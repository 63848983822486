<template>
	<div class="content">
		<img v-if="this.atLanguage == 'en_US'" src="../../assets/img/list/listDetailsEn.jpg" alt="" />
		<img v-else src="../../assets/img/list/listDetailsZh.jpg" alt="" />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				atLanguage: 'en_US'
			}
		},
		created() {
			
		},
		mounted() {
			let language = this.$route.query.language;
			if (language == 'zh_CN') {
				this.atLanguage = 'zh_CN'
			} else{
				this.atLanguage = 'en_US'
			}
		},
		methods: {
			
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
	}
	img {
		display: block;
		width: 100%;
	}
</style>